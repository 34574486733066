import * as React from 'react';
import {FormikContextType} from "formik/dist/types";
import {FormikValues, useField, useFormikContext} from "formik";
import {InputAdornment, SxProps, TextField} from "@mui/material";

type Props = {
    name: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    arrayName?: string;
    iteration?: number;
    sx?: SxProps;
}

const PaymentFormPaymentAmount = ({name, onChange, arrayName, iteration, sx}: Props) => {
    const fieldName = arrayName && iteration !== undefined ? `${arrayName}[${iteration}].${name}` : name;
    const [field, meta] = useField(fieldName)
    const {setFieldValue}: FormikContextType<FormikValues> = useFormikContext();

    return <>
        <TextField
            name={name}
            type="text"
            label="Payment Amount"
            placeholder="Payment Amount"
            value={field.value}
            disabled={false}
            onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
                await setFieldValue(fieldName, event.target.value.replace(/(?!\.)\D/g,''));
                onChange && onChange(event);
            }}
            InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            sx={{
                my: 2,
                ...sx,
            }}
            error={Boolean(meta.error)}
            helperText={(meta.error && Boolean(meta.error)) ? meta.error.toString() : ''}
        />
    </>
}

export default PaymentFormPaymentAmount;
