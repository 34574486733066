import {PaymentElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {FormikValues} from "formik";
import {useContext, useEffect} from "react";
import {Box} from "@mui/material";
import {CreateSetupIntentResponse} from "@/types/CreateSetupIntentResponse.ts";
import {useCartContext} from "@/components/Providers/CartProvider.tsx";
import {jwtContext} from "@/components/Providers/JWTProvider.tsx";
import useStripeCompleteSetupIntent from "@/hooks/useStripeCompleteSetupIntent.tsx";
import {useHistory} from "react-router-dom";

type Props = {
    cartId: string | null;
    courseSectionId: string | null;
    submitValues: FormikValues | null;
    payNowAmount: number;
    setIsProcessing: React.Dispatch<React.SetStateAction<boolean>>;
    handleModalClose?: (showCancelModal?: boolean, showConfirmModal?: boolean) => void;
    stripeErrorMessage: string;
    setStripeErrorMessage: (stripeErrorMessage: string) => void;
}

const StripeForm = (
    {
        cartId,
        courseSectionId,
        submitValues,
        payNowAmount,
        setIsProcessing,
        handleModalClose,
        stripeErrorMessage,
        setStripeErrorMessage,
    }: Props) => {
    const stripe = useStripe();
    const elements = useElements();
    const user = useContext(jwtContext);
    const completeSetupIntent = useStripeCompleteSetupIntent();
    const {refreshCart, cartPaymentSuccess} = useCartContext();
    const history = useHistory();

    if (!cartId && !courseSectionId) {
        setStripeErrorMessage('Cart ID or Course Section ID required for payment processing.');
    }

    const handleStripeSubmit = async () => {
        if (!stripe || !elements) {
            return;
        }

        setIsProcessing(true);

        const result = await stripe.confirmSetup({
            elements,
            redirect: 'if_required',
            confirmParams: {
                return_url: `${window.origin}/payment-complete?`
                    + `cartId=${cartId}`
                    + `&payNowAmount=${submitValues?.partialPaymentAmount ?? payNowAmount}`
            },
        });

        if (result.error !== undefined && result.error.message !== undefined) {
            setStripeErrorMessage(result.error.message);
        }

        // if redirect is not required by payment method:
        const setupIntentClientSecret = result.setupIntent?.client_secret;
        const setupIntentId = result.setupIntent?.id;

        if (user && setupIntentClientSecret && setupIntentId) {
            const retrieveSetupIntentResponse = await stripe.retrieveSetupIntent(setupIntentClientSecret);

            if (retrieveSetupIntentResponse.error) {
                setStripeErrorMessage(
                    retrieveSetupIntentResponse.error.message ?? 'An unknown stripe error has occurred.'
                );
                return;
            }

            const response = await completeSetupIntent(
                user,
                setupIntentId,
                setupIntentClientSecret,
                retrieveSetupIntentResponse.setupIntent.payment_method as string,
                cartId,
                courseSectionId,
                payNowAmount,
                retrieveSetupIntentResponse.setupIntent.id as string,
            );

            if (response.ok) {
                const json = await response.json() as CreateSetupIntentResponse;

                if (json.errorCode !== 0) {
                    setStripeErrorMessage(json.message);
                    return;
                }

                localStorage.removeItem('registration-course-' + courseSectionId)
                refreshCart();

                if (handleModalClose) {
                    handleModalClose(false, false);
                }

                history.push('/my-registrations');
                await cartPaymentSuccess(`Payment of $${payNowAmount.toString()} completed.`);
            } else {
                setStripeErrorMessage('An unknown error has occurred.');
            }
        }

        setIsProcessing(false);
    };

    useEffect(() => {
        if (submitValues) {
            void handleStripeSubmit();
        }
    }, [submitValues]);

    return <>
        <PaymentElement/>
        {stripeErrorMessage && <Box sx={{color: 'red'}}>{stripeErrorMessage}</Box>}
    </>

}

export default StripeForm;
