import NavHeader from "../components/Navigation/NavHeader";
import {Col, Container, Row} from "react-bootstrap";
import {useNotice} from "../components/Providers/NoticeProvider";
import ReactMarkdown from 'react-markdown';

const Instructions = () => {
    const [notice] = useNotice();

    return (
        <>
            <NavHeader showNav={true}/>
            <Container>
                <Row className="d-flex flex-row justify-content-center">
                    <Col sm={12} className="bg-white py-3 mx-0 px-3 main-content" style={{marginTop: '30px'}}>
                        <ReactMarkdown className="markdown">{notice.instructions}</ReactMarkdown>
                    </Col>
                </Row>
            </Container>
            <Container>

            </Container>
        </>
    );
};

export default Instructions;
