import React, {useEffect, useMemo} from 'react';
import {apiEndpoint, authenticatedApiFetch, jwtContext} from "@/components/Providers/JWTProvider.tsx";
import {Cart, initialCartState, RawCart} from "@/types/Cart.ts";
import {ProgramsProviderContext} from "@/components/Providers/ProgramsProvider.tsx";
import BigNumber from "bignumber.js";
import {useNotice} from "@/components/Providers/NoticeProvider.tsx";

type CartState = {
    loading: boolean;
    showAlert: boolean;
    message: string;
    alertVariant: string;
};

type CartContextInterface = {
    cart : Cart | undefined;
    deleteCartItem : (cartItemId: string) => void;
    emptyCart : () => void;
    addCartItem : (courseSectionId: string, withTimer?: boolean) => void;
    refreshCart : () => void;
    cartPaymentSuccess : (message: string) => Promise<void>;
    getCartAlertStatus : () => CartState;
    resetCartAlertStatus : () => void;
    getCartTotal : () => number;
    getOriginalCartTotal : () => number;
    cartIsEmpty : () => boolean;
};

type CartContextProviderProps = {children : React.ReactNode}

const CartContext = React.createContext<CartContextInterface>({} as CartContextInterface);

const CartContextProvider = ({children} : CartContextProviderProps) : JSX.Element => {
    const user = React.useContext(jwtContext);
    const [programsState, , , , , , ,refreshPrograms] = React.useContext(ProgramsProviderContext);
    const [cartStatus, setCartAlertStatus] = React.useState(initialCartState);
    const apiFetch = authenticatedApiFetch();
    const [notice] = useNotice();
    const timer = notice.cartCountdownTimer ?? 10;

    const [rawCart, setCart] = React.useState<RawCart>();
    const [cartError, setCartError] = React.useState<string | null>(null);

    const cart = useMemo(() => {
        if (!rawCart) return undefined;
        return {
            id: rawCart.id,
            cartItems: rawCart.cartItems.map(cartItem => {
                const program = programsState.programs.find(p => p.programUUID === cartItem.programId);
                const courseSection = program?.courses.find(c => c.courseSelectionUUID === cartItem.courseSectionId);

                if (!courseSection) console.error('cannot find course section', program, courseSection, cartItem);

                return {
                    id: cartItem.id,
                    name: courseSection?.name ?? 'error',
                    courseSectionId: courseSection?.courseSelectionUUID ?? 'error',
                    deliveryMethod: courseSection?.deliveryMethod ?? '',
                    sectionNumber:courseSection?.sectionNumber ?? 0,
                    dateStartFull: courseSection?.dateStartFull ?? 'error',
                    dateEndFull: courseSection?.dateEndFull ?? 'error',
                    meetingTime: courseSection?.meetingTime ?? 'error',
                    cost: BigNumber(courseSection?.cost ?? 999).minus(courseSection?.paidAmount ?? 0).toNumber(),
                    originalCost: courseSection?.cost ?? 999
                }
            })
        }
    }, [rawCart, programsState]);

    const fetchCart = async () => {
        const url = new URL('/v1/cart/', apiEndpoint);
        let init: RequestInit = {method: 'GET'};

        const response = await apiFetch(url.toString(), init);

        if (!response.ok) {
            setCartError('There was an error fetching the cart')
            return null;
        }

        const cartResponse = await response.json() as RawCart;

        setCart(cartResponse);
        return cartResponse;
    };

    const deleteCartItem = async (cartItemId: string) => {
        const url = new URL(`/v1/cart/cartItem/${cartItemId}`, apiEndpoint);
        let init: RequestInit = {method: 'DELETE'};

        const response = await apiFetch(url.toString(), init);

        if (!response.ok) {
            setCartError('There was an error deleting the cart item');
            return null;
        }

        await refreshPrograms();
        const updatedCart = await fetchCart();

        updatedCart?.cartItems.length
            ? localStorage.setItem('cart-' + user?.studentId, JSON.stringify({
                timeout: new Date(Date.now() + (timer * 60 * 1000)).toJSON()}))
            : localStorage.removeItem('cart-' + user?.studentId);
    };

    const emptyCart = async () => {
        const url = new URL(`/v1/cart/empty`, apiEndpoint);
        let init: RequestInit = {method: 'DELETE'};

        const response = await apiFetch(url.toString(), init);

        if (!response.ok) {
            setCartError('There was an error emptying the cart');
            return null;
        }

        localStorage.removeItem('cart-' + user?.studentId);
        await refreshPrograms();
        await fetchCart();
    }

    const addCartItem = async (courseSectionId: string, withTimer?: boolean) => {
        const url = new URL(`/v1/cart/add`, apiEndpoint);
        let init: RequestInit = {method: 'PUT'};

        init.body = JSON.stringify({
            courseSectionId: courseSectionId,
        });

        const response = await apiFetch(url.toString(), init);

        if (!response.ok) {
            setCartError('There was an error adding cart item');
            return null;
        }

        localStorage.removeItem('registration-course-' + courseSectionId);

        if (withTimer || localStorage.getItem('cart-' + user?.studentId)) {
            localStorage.setItem('cart-' + user?.studentId, JSON.stringify({
                timeout: new Date(Date.now() + (timer * 60 * 1000)).toJSON(),
            }));
        }

        await refreshPrograms();
        await fetchCart();
    }

    const refreshCart = async () => {
        await refreshPrograms();
        await fetchCart();
    }

    const cartPaymentSuccess = async (message: string) => {
        setCartAlertStatus({
            loading: false,
            showAlert: true,
            message: message,
            alertVariant: 'success',
        });
    }

    const getCartAlertStatus = () => {
        return cartStatus;
    }

    const resetCartAlertStatus = () => {
        setCartAlertStatus(initialCartState);
    }

    const getOriginalCartTotal = () => {
        const costs = cart?.cartItems.map(item => item.originalCost);
        return costs?.length ? costs.reduce((sum, num) => sum + num) : 0;
    }

    const getCartTotal = () => {
        const costs = cart?.cartItems.map(item => item.cost);
        return costs?.length ? costs.reduce((sum, num) => sum + num) : 0;
    }

    const cartIsEmpty = () : boolean => {
        return !(cart && cart.cartItems.length > 0);
    }

    const value = {
        cart,
        deleteCartItem,
        addCartItem,
        refreshCart,
        emptyCart,
        cartPaymentSuccess,
        getCartAlertStatus,
        resetCartAlertStatus,
        getCartTotal,
        getOriginalCartTotal,
        cartIsEmpty
    };

    useEffect(() => {
        if (user) {
            void fetchCart()
        }

        if (cartError) {
            throw new Error(cartError);
        }
    }, []);

    return (
        <CartContext.Provider value={value}>
            {children}
        </CartContext.Provider>
    )
};

const useCartContext = () : CartContextInterface => {
    const context = React.useContext(CartContext)

    if (Object.keys(context).length === 0) {
        throw new Error('useCartContext must be used within a CartContextProvider')
    }

    return context
}

export {CartContextProvider, useCartContext};
