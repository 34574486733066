import React from 'react';
import NavBase from './NavBase';
import {Nav, Navbar} from "react-bootstrap";
import {isStudentId, jwtContext} from "../Providers/JWTProvider";
import NavButton from "@/components/Navigation/NavButton.tsx";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ContactsIcon from '@mui/icons-material/Contacts';
import PersonIcon from '@mui/icons-material/Person';
import InfoIcon from '@mui/icons-material/Info';
import LogoutIcon from '@mui/icons-material/Logout';
import {useCartContext} from "@/components/Providers/CartProvider.tsx";
import CartTimeoutBanner from "@/components/CartTimeoutBanner.tsx";

type Props = {
    showNav ?: boolean;
}

const NavHeader = ({showNav} : Props) => {
    const user = React.useContext(jwtContext)
    const {cart} = useCartContext();

    return (<>
        <NavBase>
            <div className="text-right d-flex flex-row">
                <Navbar.Toggle aria-controls="chris-nav" />
                {showNav && (
                <Navbar.Collapse id="glenbrook-nav" className="">
                    <Nav className="mr-auto">
                        <NavButton
                            text='Cart'
                            link='/cart'
                            icon={<ShoppingCartIcon/>}
                            badges={cart?.cartItems.length}
                        />

                        <NavButton
                            text='Register'
                            link='/programs'
                            icon={<PersonAddIcon/>}
                        />

                        <NavButton
                            text='My Registrations'
                            link='/my-registrations'
                            icon={<ContactsIcon/>}
                        />

                        {user && !isStudentId(user.studentId) && (
                            <NavButton
                                text='My Profile'
                                link='/edit-profile'
                                icon={<PersonIcon/>}
                            />
                        )}
                        <NavButton
                            text='Instructions'
                            link='/instructions'
                            icon={<InfoIcon/>}
                        />
                        <NavButton
                            text='Sign Out'
                            link='/signout'
                            icon={<LogoutIcon/>}
                        />
                    </Nav>
                </Navbar.Collapse>
                )}
            </div>
        </NavBase>
        <CartTimeoutBanner/>
        </>
    );
};

export default NavHeader;
