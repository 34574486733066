import {ReactNode} from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Logo from '../Branding/Logo';

type Props = {
    children ?: ReactNode;
}

const NavBase = ({children} : Props) => {
    return (
        <Navbar expand="lg" bg="dark" className="header-bg shadow-sm navbar-light">
            <Container className="pr-0 align-items-center">
                <Navbar.Brand href="#">
                    <Logo/>
                </Navbar.Brand>
                {children}
            </Container>
        </Navbar>

    );
};

export default NavBase;
