import {useCallback} from 'react';
import {User} from "@/components/Providers/JWTProvider.tsx";

const useStripeCompleteSetupIntent = () : (
user : User,
setupId : string,
setupClientSecret : string,
paymentMethod : string,
cartId : string | null,
courseSectionId : string | null,
payNowAmount : number,
transactionId : string,
) => Promise<Response> => {
    return useCallback(async (
        user : User,
        setupId : string,
        setupClientSecret : string,
        paymentMethod : string,
        cartId : string | null,
        courseSectionId : string | null,
        payNowAmount : number,
        transactionId : string,
    ) : Promise<Response> => {
        const url = new URL('/v1/payment/complete-setup-intent', import.meta.env.VITE_APP_API_ENDPOINT);

        return await user.apiFetch(url.toString(), {
            method: 'POST',
            body: JSON.stringify({
                setupId: setupId,
                setupClientSecret: setupClientSecret,
                paymentMethod: paymentMethod,
                cartId: cartId,
                courseSectionId: courseSectionId,
                payNowAmount: payNowAmount,
                paymentType: 'stripe',
                transactionId: transactionId,
            }),
        });
    }, []);
};

export default useStripeCompleteSetupIntent;
