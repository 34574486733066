import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import TripOriginIcon from '@mui/icons-material/TripOrigin';
import {ChangeEvent, useEffect} from 'react';
import {FormikContextType} from "formik/dist/types";
import {FormikValues, useField, useFormikContext} from "formik";
import {Box, Button, SxProps} from "@mui/material";

type Props = {
    name: string;
    onChange?: (e: ChangeEvent<any>) => void;
    arrayName?: string;
    iteration?: number;
    options: {value: string, label: string, disabled?: boolean, hidden?: boolean, selected?: boolean}[];
    sx ?: SxProps,
}

const ButtonCollection = (
    {
        name,
        onChange,
        arrayName,
        iteration,
        options,
        sx,
    }: Props) => {
    const fieldName = arrayName && iteration !== undefined ? `${arrayName}[${iteration}].${name}` : name;
    const [field, meta] = useField(fieldName)
    const {setFieldValue}: FormikContextType<FormikValues> = useFormikContext();

    const defaultSelectedOption = options.filter((o) => o.selected);

    useEffect(() => {
        const setDefaultValue = async () => {
            if (defaultSelectedOption.length) {
                await setFieldValue(fieldName, defaultSelectedOption[0].value);
            }
        }

        setDefaultValue().catch(console.error);
    }, [defaultSelectedOption]);

    return <Box sx={sx}>
        {options.map(option => {
            const selected = option.selected || field.value === option.value;

            return !option.hidden && <Button
                key={`toggle-option-${option.value}`}
                value={option.value}
                disabled={option.disabled}
                onClick={async (event) => {
                    await setFieldValue(fieldName, option.value);
                    onChange && onChange(event)
                }}
                sx={{
                    borderRadius: '8px',
                    mr: 2,
                    mb: 1,
                    border: '1px solid #ddd',
                    p: '18px',
                    backgroundColor: selected ? '#631d79' : '#f8f8f8',
                    color: selected ? '#fff' : '#000',
                    fontWeight: selected ? 800 : 400,
                    '&:hover': {
                        backgroundColor: selected ? '#631d79' : '#f8f8f8',
                    }
                }}
            >
                {selected && <TripOriginIcon fontSize="small" sx={{mr: 1, color: '#fff'}}/>}
                {!selected && <RadioButtonUncheckedIcon fontSize="small" sx={{mr: 1}}/>}
                {option.label}
            </Button>
        })}
        {meta.error && <Box
            sx={{
                color: 'red',
                ml: 2,
                mt: 1,
            }}
        >
            {meta.error.toString()}
        </Box>}
    </Box>;
}

export default ButtonCollection;
