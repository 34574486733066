import {useEffect, useState} from 'react';

type Props = {
    timeout: string;
    callback?: () => void;
}

const LocalStorageTimer = ({timeout, callback}: Props) => {
    const [timeDisplay, setTimeDisplay] = useState<string | null>('');

    useEffect(() => {
        let timer: ReturnType<typeof setTimeout> | null = null;

        const checkForCleanup = () => {
            const timeDiff = (new Date(timeout)).getTime() - (new Date()).getTime();
            const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

            if (timeDiff > 0) {
                setTimeDisplay(`${minutes}:${seconds.toString().padStart(2, '0')}`);

                timer = setTimeout(() => {
                    checkForCleanup();
                }, 900)
            } else {
                if (callback) {
                    callback();
                }
            }
        }

        if (timeout) {
            const timeDiff = (new Date(timeout)).getTime() - (new Date()).getTime();

            if (timeDiff > 0) {
                checkForCleanup();
            }
        }

        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        }
    }, [callback, timeout]);

    return (
        <span style={{fontWeight: 800}}>{timeDisplay}</span>
    );
}

export default LocalStorageTimer;
