import {MaterialReactTable, type MRT_ColumnDef, useMaterialReactTable} from "material-react-table";
import {useEffect, useMemo} from "react";
import {DisplayCourse} from "@/types/DisplayCourse.ts";

type Props = {
    data : DisplayCourse[];
};

const DisplayCourseTable = ({data}: Props) => {
    const displayCoursesColumns = useMemo<MRT_ColumnDef<DisplayCourse>[]>(
        () => [
            {
                accessorKey: 'courseSelectionUUID',
                header: '',
            },
            {
                accessorKey: 'subject',
                header: 'Subject',
                size: 10,
            },
            {
                accessorKey: 'courseNumber',
                header: 'COURSE #',
                size: 10,
            },
            {
                accessorKey: 'sectionNumber',
                header: 'Section #',
                size: 10,
            },
            {
                accessorKey: 'name',
                header: 'DESCRIPTION',
                size: 200,
            },
            {
                accessorKey: 'term',
                header: 'Term',
                size: 10,
            },
            {
                accessorKey: 'dates',
                header: 'DATES',
                size: 110,
                grow: true,
            },
            {
                accessorKey: 'meetingDays',
                header: 'DAYS',
                size: 10,
            },
            {
                accessorKey: 'meetingTime',
                header: 'TIME',
                size: 150,
                grow: true,
            },
            {
                accessorKey: 'credits',
                header: 'CREDIT',
                size: 10,
            },
            {
                accessorKey: 'cost',
                header: 'DUE',
                size: 10,
            },
            {
                accessorKey: 'status',
                header: 'AVAILABILITY',
                size: 200,
                grow: true
            },
            {
                accessorKey: 'statusOriginal',
                header: '',
                visibleInShowHideMenu: false,
            },
        ],
        [],
    );

    const displayCoursesTable = useMaterialReactTable({
        columns: displayCoursesColumns,
        data: data,
        enableTopToolbar: false,
        enableColumnActions: false,
        enableColumnFilters: false,
        enableDensityToggle: false,
        enableFilterMatchHighlighting: false,
        enableFullScreenToggle: false,
        enableGlobalFilter: false,
        enableHiding: false,
        enablePagination: false,
        icons: {
            SortIcon: () => null,
        },
        initialState: {
            columnVisibility: {
                courseSelectionUUID: false,
                statusOriginal: false
            },
            pagination: {pageSize: 40, pageIndex: 0},
        },
        muiTableHeadCellProps: {
            sx: {
                backgroundColor: '#f2e4ff',
                fontWeight: '700',
                fontSize: '14px',
                padding: '12px',
                '& span': {
                    backgroundColor: 'red',
                    display: 'none',
                }
            },
        },
        muiTableBodyCellProps: {
            sx: {
                fontWeight: 'normal',
                fontSize: '14px',
                padding: '12px',
            },
        },
    });

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 990) { // Adjust breakpoint as needed
                displayCoursesTable.setColumnVisibility((prev) => ({
                    ...prev,
                    subject: false,
                    courseNumber: false,
                    sectionNumber: false,
                    name: true,
                    term: false,
                    dates: true,
                    meetingDays: false,
                    meetingTime: false,
                    credits: false,
                    cost: false,
                    deliveryMethod: false,
                    status: true,
                    courseSelectionUUID: false,
                    statusOriginal: false
                }));
            } else {
                displayCoursesTable.setColumnVisibility({
                    subject: true,
                    courseNumber: true,
                    sectionNumber: true,
                    name: true,
                    term: true,
                    dates: true,
                    meetingDays: true,
                    meetingTime: true,
                    credits: true,
                    cost: true,
                    deliveryMethod: true,
                    status: true,
                    courseSelectionUUID: false,
                    statusOriginal: false
                });
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return <MaterialReactTable table={displayCoursesTable}/>;
}

export default DisplayCourseTable;
