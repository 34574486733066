import {Badge, IconButton, SvgIconProps, Typography} from "@mui/material";
import {useHistory} from "react-router-dom";
import {ReactElement} from "react";

type Props = {
    text : string;
    link : string;
    icon ?: ReactElement<SvgIconProps>;
    badges ?: number;
}

const displayIcon = (icon ?: ReactElement<SvgIconProps>, badges ?: number) : ReactElement => {
    if (icon && badges) {
        return <Badge badgeContent={badges} color="primary">
            {icon}
        </Badge>
    }

    if (icon) {
        return icon;
    }

    return <></>;

}

const NavButton = ({text, link, icon, badges} : Props) => {
    const history = useHistory();

    return <IconButton
        color="inherit"
        aria-label={link}
        onClick={() => history.push(link)}
        sx={{
            display: 'flex',
            flexDirection: 'column',
            "&:hover": {
                backgroundColor: "#FFF"
            }
        }}
        disableRipple
    >
        {displayIcon(icon, badges)}
        <Typography variant='body1' sx={{p: 1}}>{text}</Typography>
    </IconButton>;
};

export default NavButton;
