import {BrowserRouter, Route, Switch} from 'react-router-dom';
import JWTProvider, {JWTInstructorProvider} from "./components/Providers/JWTProvider";
import NoticeProvider from "./components/Providers/NoticeProvider";
import RegisterCommunityMember from './pages/RegisterCommunityMember';
import Routes from "./Routes";
import InstructorSignIn from "./pages/instructor/InstructorSignIn";
import InstructorProgram from "./pages/instructor/InstructorProgram";
import InstructorProgramsProvider from "./components/Providers/InstructorProgramsProvider";
import ActivityDetailView from "./pages/instructor/ActivityDetailView";
import InstructorProgramList from "./pages/instructor/InstructorProgramList";
import {ScheduledMaintenance} from "./pages/ScheduledMaintenance";

const Guard = () => (
    <NoticeProvider>
        <Switch>
            <Route path="/instructor">
                <JWTInstructorProvider>
                    <Route path="/instructor/signin" exact component={InstructorSignIn}/>
                    <InstructorProgramsProvider>
                        <Route path="/instructor" exact component={InstructorProgramList}/>
                        <Route path="/instructor/program/:programId" exact component={InstructorProgram}/>
                        <Route path="/instructor/course/:programId/:courseId" exact component={ActivityDetailView}/>
                    </InstructorProgramsProvider>
                </JWTInstructorProvider>
            </Route>
            <JWTProvider>
                <Routes/>
            </JWTProvider>
        </Switch>
    </NoticeProvider>
);

const App = () => {
    return (<>
            <ScheduledMaintenance>
                <BrowserRouter>
                    <Switch>
                        <Route path="/register-community-member" exact component={RegisterCommunityMember} />
                        <Route path="/" component={Guard} />
                    </Switch>
                </BrowserRouter>
            </ScheduledMaintenance>
    </>);
}

export default App;
