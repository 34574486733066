import {Container, SxProps} from "@mui/material";

type Props = {
    children: React.ReactNode;
    sx?: SxProps;
}

const MainDisplayContainer = ({children, sx} : Props) => (
    <Container
        sx={{
            p: 0,
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            height: 'calc(100vh - 158px)',
            ...sx,
        }}
    >
        {children}
    </Container>
);

export default MainDisplayContainer;
