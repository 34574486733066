import React from 'react';
import {Button, Modal, Spinner} from 'react-bootstrap';

type Props = {
    show : boolean;
    title : string | null;
    children : any;
    handleClose? : () => void | null | Promise<void>;
    handleConfirm? : () => void | null;
    cancelText? : string;
    confirmText? : string;
    size? : any;
    loading? : boolean;
    hideCloseButton? : boolean;
    refForm? : string;
    hideConfirmButton? : boolean;
    btnClass? : string;
    modalClass? : string;
    backdrop? : 'static' | true | false;
    keyboard? : boolean;
}

const ModalBase = ({
    show,
    handleClose,
    title,
    handleConfirm,
    cancelText,
    confirmText,
    size,
    loading,
    hideCloseButton,
    refForm,
    hideConfirmButton,
    btnClass,
    modalClass,
    children,
    backdrop = true,
    keyboard = true
} : Props) => (
    <Modal size={size ? size : 'lg'} show={show} onHide={handleClose} className={modalClass} backdrop={backdrop} keyboard={keyboard}>
        <Modal.Header closeButton className="border-0">
            {title && (<h3 className="text-primary">{title}</h3>)}
        </Modal.Header>
        <Modal.Body className="my-0 py-0">{children}</Modal.Body>
        <Modal.Footer className="border-0">
            <div className="d-flex justify-content-end flex-column-reverse flex-sm-row">
                {!hideCloseButton && (
                    <Button
                        variant="secondary"
                        onClick={handleClose}
                        className={btnClass ?
                            btnClass + ' w-auto mr-3 mb-3 mb-sm-0' : 'w-auto mr-3 mb-3 mb-sm-0'
                        }
                    >
                        {cancelText ? cancelText : 'Close'}
                    </Button>
                )}
                {!hideConfirmButton && (
                    <Button
                        variant="primary"
                        onClick={handleConfirm}
                        form={refForm}
                        type="submit"
                        className={btnClass ?
                            btnClass + ' w-auto mr-3 mb-3 mb-sm-0' : 'w-auto mr-3 mb-3 mb-sm-0'
                        }
                    >
                        {(loading ?
                            <React.Fragment>
                                <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true"/>
                                &nbsp; Loading...
                            </React.Fragment>
                        :
                            <React.Fragment>{confirmText ? confirmText : 'Confirm'}</React.Fragment>
                        )}
                    </Button>
                )}
            </div>
        </Modal.Footer>
    </Modal>
);

export default (ModalBase);
