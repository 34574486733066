import React, {useCallback} from 'react';
import {jwtContext} from "@/components/Providers/JWTProvider.tsx";

const useStripeCreateSetupIntent = () : () => Promise<Response | void> => {
    const user = React.useContext(jwtContext);

    return useCallback(async () : Promise<Response | void> => {
        const url = new URL('/v1/payment/create-setup-intent', import.meta.env.VITE_APP_API_ENDPOINT);

        if (user){
            return await user.apiFetch(url.toString(), {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            });
        }
    }, []);
};

export default useStripeCreateSetupIntent;
