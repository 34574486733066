import {Grid, Stack, SxProps, Typography} from "@mui/material";
import {formatter} from "@/Helpers/formatter.ts";

type Props = {
    totalCost : number;
    cartIsEmpty : boolean;
    errorMessage : string;
    sx ?: SxProps;
}

const TotalRow = ({totalCost, cartIsEmpty, errorMessage, sx = {}}: Props) => {
    return <Stack
        sx={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            px: 2,
            pb: 2,
            ...sx,
        }}
    >
        <Typography variant={'h6'} sx={{mt: 3, mb: 1, fontWeight: 800}}>
            Total:
        </Typography>
        <Typography variant={'h6'} sx={{mt: 3, mb: 1, fontWeight: 800}}>
            {formatter.format(totalCost)}
        </Typography>
        {cartIsEmpty && <Typography variant={'h6'} sx={{mt: 3, mb: 1, fontWeight: 800}}>
            Your cart is empty.
        </Typography>}
        {errorMessage && <Grid item xs={12} sx={{color: 'red'}}>
            {errorMessage}
        </Grid>}
    </Stack>;
}

export default TotalRow;
