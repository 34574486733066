import {Col, Row} from "react-bootstrap";
import CircularLoadingIndicator from "../CircularLoadingIndicator";
import ProgramSelectField from "../Forms/Fields/ProgramSelectField";

type Props = {
    availablePrograms : Array<string>,
    loading : boolean;
    setActiveProgram : (activeProgram : string) => void;
};

const ProgramSelection = ({availablePrograms, loading, setActiveProgram} : Props) => {
    return (
        <Row>
            <Col sm={12} className="bg-white py-3 mx-0 px-0 main-content d-flex flex-row justify-content-center">
            {loading ? (
                <CircularLoadingIndicator/>
            ) : (
                <div className="d-flex flex-column align-items-center">
                    {availablePrograms.length < 1 ? (
                        <h2 className="mt-5">No available programs at this time.</h2>
                    ) : (
                        <>
                            <h2 className="my-5">Choose a Program to View Activities / Courses</h2>
                            <ProgramSelectField
                                availablePrograms={availablePrograms}
                                setActiveProgram={setActiveProgram}
                            />
                        </>
                    )}
                </div>
            )}
            </Col>
        </Row>
    );
};

export default ProgramSelection;
