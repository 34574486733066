import React, {useCallback} from 'react';
import {apiEndpoint, jwtContext} from "@/components/Providers/JWTProvider.tsx";

const useConfirmMailIn = () : (courseSectionIds : string[], cartId ?: string) => Promise<Response | void> => {
    const user = React.useContext(jwtContext);

    return useCallback(async (courseSectionIds : string[], cartId ?: string) : Promise<Response | void> => {
        const url = new URL(`/v1/payment/confirm-mailin`, apiEndpoint);

        if (!user) {
            return;
        }

        let init: RequestInit = {method: 'POST'};
        init.body = JSON.stringify({
            paymentType: 'mail',
            courseSectionIds: courseSectionIds,
            studentId: user.studentId,
            cartId: cartId
        });

        return await user.apiFetch(url.toString(), init);
    }, []);
};

export default useConfirmMailIn;
