import {useContext, useEffect, useMemo, useState} from 'react';
import {Col, Row} from "react-bootstrap";
import {
    ProgramCourse,
    STATUS_PARTIAL,
    STATUS_REGISTERED,
    STATUS_REGISTERED_WITH_CREDITS,
    STATUS_REGISTERED_WITH_CREDITS_PENDING_PAYMENT,
    STATUS_WAITLISTED,
} from "../Providers/ProgramsProvider";
import CourseDescriptionModal from "../Modal/CourseDescriptionModal";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ProgramSelectField from "../Forms/Fields/ProgramSelectField";
import RegisterButton from "./RegisterButton";
import {jwtContext} from "../Providers/JWTProvider";
import DisplayCourseTable from "@/components/Program/DisplayCourseTable.tsx";
import {DisplayCourse} from "@/types/DisplayCourse.ts";
import PartialPaymentsCheckbox from "@/components/Forms/Fields/PartialPaymentsCheckbox.tsx";
import PartialPaymentsModal from "@/components/Modal/PartialPaymentsModal.tsx";
import BigNumber from "bignumber.js";

type Props = {
    programName : string,
    courses : ProgramCourse[],
    setActiveProgram? : (activeProgram : string) => void;
    myRegistrations? : boolean;
};

const ProgramCourses = (
    {
        programName,
        courses,
        myRegistrations
    } : Props) => {
    const [courseDescription, setCourseDescription] = useState<ProgramCourse | null>(null);
    const [courseCategory, setCourseCategory] = useState<string | null>(null);
    const [showPartialPayments, setShowPartialPayments] = useState<boolean>(false);
    const [showPartialPaymentsModal, setShowPartialPaymentsModal] = useState<boolean>(false);
    const [showCourseDescriptionModal, setShowCourseDescriptionModal] = useState<boolean>(false);
    const user = useContext(jwtContext)

    const studentDisplay =`${user?.studentId} - ${user?.nameFirst} ${user?.nameLast}`;

    const mapCoursesForDisplay = (course : ProgramCourse) : DisplayCourse => {
        const localStorageTimeout = localStorage.getItem('registration-course-' + course.courseSelectionUUID);
        return {
            courseSelectionUUID : course.courseSelectionUUID,
            subject : course.category,
            term : course.term,
            courseNumber : course.courseNumber,
            name : <div onClick={() => {
                setCourseDescription(course);
                setShowCourseDescriptionModal(true);
            }} className="btn-link cursor-pointer"><FontAwesomeIcon
                icon={faInfoCircle}
                size="sm"
                className="cursor-pointer mr-2"
            />{course.name}</div>,
            dates : course.dates,
            meetingDays : course.meetingDays,
            meetingTime : course.meetingTime,
            credits : course.credits,
            cost : `$${BigNumber(course.cost).minus(course.paidAmount ?? 0)}`,
            sectionNumber : course.sectionNumber,
            status : <RegisterButton course={course} programName={programName} localStorageTimeout={localStorageTimeout}/>,
            statusOriginal : course.status,
            deliveryMethod: course.deliveryMethod,
            price: course.cost,
            localStorageTimeout: localStorageTimeout,
        };
    };

    let filteredCourses = useMemo(() => {
        let filteredCourses = courses;
        if (courseCategory) {
            filteredCourses = filteredCourses.filter((course) => course.category === courseCategory);
        }
        return filteredCourses;
    }, [courses, courseCategory]);

    const partiallyPaidCourses = useMemo(() => {
        return filteredCourses.filter((course) => course.registrationStatus === 'partial');
    }, [filteredCourses]);

    if (showPartialPayments) {
        filteredCourses = partiallyPaidCourses
    }

    let displayCourses = filteredCourses.map(mapCoursesForDisplay).sort((a, b) : number => {
        const subjectOrder = a.subject.localeCompare(b.subject);
        const courseOrder = a.courseNumber.localeCompare(b.courseNumber);
        const courseSelectionIdOrder = a.courseSelectionUUID.localeCompare(b.courseSelectionUUID);
        const timeOrder = a.meetingTime.localeCompare(b.meetingTime);

        return subjectOrder || courseOrder || courseSelectionIdOrder || -timeOrder;
    });

    useEffect(() => {
        if (partiallyPaidCourses.length > 0
            && !showPartialPayments
            && displayCourses.find(c => c.localStorageTimeout !== null) === undefined
        ) {
            setShowPartialPaymentsModal(true);
        }
    }, [partiallyPaidCourses]);

    let uniqueCategories : Array<string> = [];
    courses.map((course) => {
        if (!uniqueCategories.includes(course.category)) {
            uniqueCategories.push(course.category);
        }
        return null;
    });

    //GLENB-444
    uniqueCategories = uniqueCategories.sort((a, b) =>
        a.localeCompare(b, undefined, {sensitivity: 'base'})
    );

    const registered = useMemo(() => {
        return displayCourses.filter((course) => course.statusOriginal === STATUS_REGISTERED);
    }, [displayCourses]);
    const waitlisted = useMemo(() => {
        return displayCourses.filter((course) => course.statusOriginal === STATUS_WAITLISTED);
    }, [displayCourses]);
    const registeredPartial = useMemo(() => {
        return displayCourses.filter((course) => course.statusOriginal === STATUS_PARTIAL);
    }, [displayCourses]);
    const registeredPendingPayment = useMemo(() => {
        return displayCourses.filter((course) => course.statusOriginal === STATUS_REGISTERED_WITH_CREDITS_PENDING_PAYMENT);
    }, [displayCourses]);
    const registeredWithCredits = useMemo(() => {
        return displayCourses.filter((course) => course.statusOriginal === STATUS_REGISTERED_WITH_CREDITS);
    }, [displayCourses]);

    const displayCoursesSorted: DisplayCourse[] = displayCourses.sort((a,b) => a.subject.localeCompare(b.subject)
        || a.courseNumber.localeCompare(b.courseNumber)
        || Math.sign(a.sectionNumber - b.sectionNumber)
    );

    if (courses.length < 1) {
        return null;
    }

    return (
        <Row className="d-flex flex-row justify-content-center">
            <Col sm={12} className="bg-white py-3 mx-0 px-0 main-content">
                {myRegistrations && (
                    <>
                        <Row>
                            <Col sm={6}>
                                <Col sm={12}>
                                    <h2>My Registrations - {programName}</h2>
                                </Col>
                            </Col>
                            <Col sm={6} className="text-right">
                                <Col sm={12}>
                                    {studentDisplay}
                                </Col>
                            </Col>
                        </Row>
                        {registered.length > 0 && (
                            <>
                                <Col sm={12} style={{paddingBottom: '20px'}}>
                                    <h3>Registered</h3>
                                </Col>
                                <DisplayCourseTable data={registered}/>
                            </>
                        )}
                        {registeredPartial.length > 0 && (
                            <>
                                <Col sm={12}>
                                    <h3>Registered Pending Partial Payment</h3>
                                </Col>
                                <DisplayCourseTable data={registeredPartial}/>
                            </>
                        )}
                        {registeredPendingPayment.length > 0 && (
                            <>
                                <Col sm={12}>
                                    <h3>Registered Pending Payment</h3>
                                </Col>
                                <DisplayCourseTable data={registeredPendingPayment}/>
                            </>
                        )}
                        {registeredWithCredits.length > 0 && (
                            <>
                                <Col sm={12}>
                                    <h3>Registered with Credits</h3>
                                </Col>
                                <DisplayCourseTable data={registeredWithCredits}/>
                            </>
                        )}
                        {waitlisted.length > 0 && (
                            <>
                                <Col sm={12}>
                                    <h3>Waitlisted</h3>
                                </Col>
                                <DisplayCourseTable data={waitlisted}/>
                            </>
                        )}
                    </>
                )}
                {!myRegistrations && (
                    <>
                        <Row>
                            <Col sm={6}>
                                <Col sm={12}>
                                    <div className="py-2">
                                        <h3>List of Activities You Are Eligible For:</h3>
                                        <div className="d-flex flex-row justify-content-start py-2">
                                            <div className="mt-2 mr-2">Filter:</div>
                                            <ProgramSelectField
                                                availablePrograms={uniqueCategories}
                                                setActiveProgram={setCourseCategory}
                                                defaultProgram={courseCategory ?? ''}
                                            />
                                            <PartialPaymentsCheckbox showPartialPayments={showPartialPayments} setShowPartialPayments={setShowPartialPayments}/>
                                        </div>
                                    </div>
                                </Col>
                            </Col>
                            <Col sm={6} className="text-right">
                                <Col sm={12}>
                                    {studentDisplay}
                                </Col>
                            </Col>
                        </Row>
                        <DisplayCourseTable data={displayCoursesSorted}/>
                        <PartialPaymentsModal
                            showPartialPaymentsModal={showPartialPaymentsModal}
                            setShowPartialPaymentsModal={setShowPartialPaymentsModal}
                            setShowPartialPayments={setShowPartialPayments}
                        />
                    </>
                )}
            </Col>
            <CourseDescriptionModal
                course={courseDescription}
                handleShowModal={setShowCourseDescriptionModal}
                show={showCourseDescriptionModal}
            />
        </Row>
    );
};

export default ProgramCourses;
