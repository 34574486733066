import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import React, {useMemo} from 'react';
import LocalStorageTimer from "@/components/LocalStorageTimer.tsx";
import {Box, Stack} from "@mui/material";
import {jwtContext} from "@/components/Providers/JWTProvider.tsx";
import {useCartContext} from "@/components/Providers/CartProvider.tsx";

const CartTimeoutBanner = () => {
    const user = React.useContext(jwtContext);
    const {cart, emptyCart} = useCartContext();

    const timeout = useMemo(() => {
        const localStorageItemString = localStorage.getItem('cart-' + user?.studentId);
        const localStorageItem = localStorageItemString ? JSON.parse(localStorageItemString) : null;
        return localStorageItem ? localStorageItem.timeout : null;
    }, [cart]);

    const cartTimeout = () => {
        emptyCart();
    }

    return (
        (cart?.cartItems.length && timeout) ? <Box sx={{px: 0}}>
        <Stack
            sx={{
                width: '100%',
                p: 2,
                backgroundColor: 'yellow',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <AccessAlarmIcon/>
            <Box sx={{pl: 2,}}>
                Please complete your checkout within the next <LocalStorageTimer
                timeout={timeout}
                callback={cartTimeout}
            />&nbsp;minutes to confirm your registrations or they will be canceled.
            </Box>
        </Stack>
    </Box> : <></>
)};

export default CartTimeoutBanner;
