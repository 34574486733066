import React from 'react';
import GeneralRegisterButton, {
    GeneralRegisterButtonProps
} from "@/components/Program/RegisterButtons/GeneralRegisterButton.tsx";

const RegisteredInCart = (props : GeneralRegisterButtonProps): React.ReactElement => (
    <GeneralRegisterButton
        buttonText="View Cart to Complete Registration."
        buttonTextAction="Switching"
        {...props}
    />
);

export default RegisteredInCart;
