import type {CartItem, RawCartItem} from "@/types/CartItem.ts";

export type Cart = {
    id: string;
    cartItems : CartItem[];
};

export type RawCart = {
    id: string;
    cartItems : RawCartItem[];
}

export const initialCartState = {
    loading: true,
    showAlert: false,
    message: '',
    alertVariant: 'success'
};
