import Form from "react-bootstrap/Form";

type Props = {
    showPartialPayments : boolean;
    setShowPartialPayments : (showPartialPayments : boolean) => void;
};

const PartialPaymentsCheckbox = ({showPartialPayments, setShowPartialPayments} : Props) => {
    return (
        <Form.Check
            className="mb-2 mt-2 ml-2"
            type={'checkbox'}
            name={'showPartialPayments'}
            label="View partially completed payments"
            checked={showPartialPayments}
            onChange={async (e) => {
                setShowPartialPayments(e.currentTarget.checked);
            }}
        />
    );
};

export default PartialPaymentsCheckbox;
