import React, {ReactNode, useCallback, useEffect} from 'react';
import {apiEndpoint, ApiFetch, jwtContext} from "./JWTProvider";
import moment from "moment";
import type {FinancialAid} from '@/Helpers/buildPrice.ts';

export const STATUS_CLOSED = 'closed';
export const STATUS_OPEN = 'open';
export const STATUS_WAITLIST = 'waitlist';
export const STATUS_CLOSED_NO_WAITLIST = 'closed-no-waitlist';
export const STATUS_REGISTERED = 'registered';
export const STATUS_WAITLISTED = 'waitlisted';
export const STATUS_PARTIAL = 'partial';
export const STATUS_REGISTERED_WITH_CREDITS = 'registered-with-credits';
export const STATUS_REGISTERED_WITH_CREDITS_PENDING_PAYMENT = 'registered-with-credits-pending-payment';
export const OVERLAP_MESSAGE = 'This course time(s) overlaps with a previously registered course.  Please check your schedule and try again.';
export const MULTIPLE_REGISTRATION_MESSAGE = 'You can only register for one section per term.';
export const ERROR_REGISTERING = 'There was an error registering you for the course.';
export const ERROR_LOADING_PROGRAMS = 'There was an error loading the list of programs.';

export type ProgramCourseStatus = typeof STATUS_OPEN
    | typeof STATUS_CLOSED_NO_WAITLIST
    | typeof STATUS_WAITLIST
    | typeof STATUS_PARTIAL
    | typeof STATUS_REGISTERED
    | typeof STATUS_WAITLISTED
    | typeof STATUS_REGISTERED_WITH_CREDITS_PENDING_PAYMENT
    | typeof STATUS_REGISTERED_WITH_CREDITS
    | typeof STATUS_CLOSED;

export type Program = {
    programUUID: string;
    name: string;
    courses: Array<ProgramCourse>;
    open: boolean;
    requiresSurvey: boolean;
    finishedSurvey: boolean;
    oneSectionPerTerm: boolean;
};

export type ProgramCourseSchool = {
    shortName: string;
    fullName: string;
    id: string;
};

export type ProgramCourse = {
    originalCost : number;
    courseUUID: string;
    courseSelectionUUID: string;
    category: string;
    courseNumber: string;
    description: string;
    dates: string;
    meetingDays: string;
    meetingTime: string;
    dateStartFull: string;
    dateEndFull: string;
    cost: number;
    paidAmount: number;
    paymentDue: boolean;
    courseCredits: number | null;
    courseQuestions: CourseQuestion[] | null;
    credits: number;
    name: string;
    status: ProgramCourseStatus;
    registrationError: boolean;
    sectionNumber: number;
    term: string;
    termID: string;
    school: ProgramCourseSchool;
    deliveryMethod: string;
    timeConflictOverride: boolean;
    allowCommunity: boolean;
    requiresPayment: boolean;
    financialAid?: FinancialAid;
    registrationStatus?: string | null;
    programCancellationMessage : string;
    oneSectionPerTermOverride:boolean;
    notes:string;
    isInCart : boolean;
};

export type CourseQuestion = {
    id: string;
    active: boolean;
    answerList: string[] | null;
    question: string;
    responseType: string;
    userSubmittedAnswer: string | null;
}

export const ALERT_ERROR = 'danger';
export const ALERT_SUCCESS = 'success';

export type AlertVariant = typeof ALERT_ERROR | typeof ALERT_SUCCESS;

type ProgramsState = {
    loading: boolean;
    showAlert: boolean;
    programs: Array<Program>;
    message: string;
    alertVariant: string;
}

export type SurveyAnswers = {
    isAtRisk: boolean;
}

type Props = {
    children?: ReactNode;
};

export const ProgramsProviderContext = React.createContext<[
    ProgramsState,
    ((activeCourse: ProgramCourse, activeProgram: string, reason ?: string) => Promise<boolean>) | null,
    (() => Promise<void>) | null,
    ((surveyAnswers: SurveyAnswers, program: Program) => Promise<boolean>) | null,
    ((activeCourse: ProgramCourse, activeProgram: string) => boolean) | null,
    ((course: ProgramCourse) => Promise<void>) | null,
    (message : string) => void,
    () => Promise<void>,
]>(
    [{
        loading: true,
        showAlert: false,
        programs: [],
        message: '',
        alertVariant: 'success'
    }, null, null, null, null, null, () => {}, async () => {}]
);

const findCourseInPrograms = (programs: Program[], courseSelectionUUID: string) => {
    let foundProgram: ProgramCourse | undefined;

    programs.forEach((program) => {
        foundProgram = program.courses.find((course) => course.courseSelectionUUID === courseSelectionUUID);
    })

    return foundProgram;
}

const programsReducer = (state: ProgramsState, action: any): ProgramsState => {
    switch (action.type) {
        case 'LOADING': {
            return {...state, loading: true, showAlert: false, alertVariant: ''}
        }
        case 'SET_PROGRAMS': {
            return {
                ...state,
                loading: false,
                showAlert: action.showAlert,
                programs: action.programs,
                alertVariant: action.alertVariant,
                message: action.message
            }
        }
        case 'SET_PROGRAM_STATUS': {
            const updatedPrograms = state.programs.map((program) => {
                program.courses = program.courses.map((course) => {
                    const foundCourseInPrograms = findCourseInPrograms(action.programs, course.courseSelectionUUID)

                    if (foundCourseInPrograms) {
                        course.status = foundCourseInPrograms.status;
                    }

                    return course
                });

                return program;
            })

            return {
                ...state,
                loading: false,
                showAlert: action.showAlert,
                programs: updatedPrograms,
                alertVariant: action.alertVariant,
                message: action.message
            }
        }
        case 'REGISTRATION_ERROR': {
            return {
                ...state,
                loading: false,
                showAlert: action.showAlert,
                message: action.message,
                programs: action.programs,
                alertVariant: action.alertVariant
            }
        }
        case 'CLEAR_REGISTRATION_ERROR': {
            return {...state, loading: false, showAlert: false, message: '', alertVariant: ''}
        }
        case 'SWITCH_PAYMENT': {
            const programs = state.programs.map((program) => ({
                ...program,
                courses: program.courses.map((c) => {
                    return c.courseSelectionUUID === action.course.courseSelectionUUID ? {...c, registrationStatus: null} : c;
                })
            }));

            return {
                ...state,
                loading: false,
                showAlert: true,
                message: 'Payment method updated.',
                programs: programs,
                alertVariant: 'success'
            }
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
};

const mapRawProgram = (rawProgram: any): Program => {
    return {
        ...rawProgram,
        courses: rawProgram.courses.length > 0 ?
            rawProgram.courses.map((rawCourse: any) => {
                return ({
                    ...rawCourse,
                    registrationError: false
                })
            })
            : []
    }
};

const csOverlap = (c: ProgramCourse, cs: ProgramCourse) => {
    const csMeetingTimeStart = moment(cs.dateStartFull).format('HH:mm');
    const csMeetingTimeEnd = moment(cs.dateEndFull).format('HH:mm');

    const cMeetingTimeStart = moment(c.dateStartFull).format('HH:mm');
    const cMeetingTimeEnd = moment(c.dateEndFull).format('HH:mm');

    if (csMeetingTimeStart === '00:00:00' || csMeetingTimeEnd === '00:00:00'
        || cMeetingTimeStart === '00:00:00' || cMeetingTimeEnd === '00:00:00') {
        return false;
    }

    if (!(csMeetingTimeStart >= cMeetingTimeStart && csMeetingTimeStart <= cMeetingTimeEnd)
        && !(csMeetingTimeEnd >= cMeetingTimeStart && csMeetingTimeEnd <= cMeetingTimeEnd)) {
        return false;
    }

    const cDOW = c.meetingDays?.split(',') ?? [];
    const csDOW = cs.meetingDays?.split(',') ?? [];
    if (!cDOW.some(d => csDOW.includes(d))) {
        return false;
    }

    const cStart = moment(c.dateStartFull, 'YYYY-MM-DD');
    const cEnd = moment(c.dateEndFull, 'YYYY-MM-DD');
    const csStart = moment(cs.dateStartFull, 'YYYY-MM-DD');
    const csEnd = moment(cs.dateEndFull, 'YYYY-MM-DD');

    return (csStart >= cStart && csStart <= cEnd)
        || (csEnd >= cStart && csEnd <= cEnd);
};

const fetchPrograms = async (apiFetch: ApiFetch) => {
    const url = new URL(`/v1/program`, apiEndpoint);
    const response = await apiFetch(url.toString(), {
        // @ts-ignore should be there
        signal: AbortSignal.timeout(10000)//10 second timeout
    });

    const data = await response.json();
    return data.map(mapRawProgram);
}

const loadPrograms = async (apiFetch: ApiFetch, setProgramsState: (param: object) => void, noLoading: boolean | undefined) => {
    try {
        const programs = await fetchPrograms(apiFetch);
        if (!noLoading) {
            setProgramsState({type: 'LOADING'});
        }

        setProgramsState({
            type: 'SET_PROGRAMS',
            programs: programs,
            showAlert: false
        });
    } catch (e) {
        setProgramsState({
            type: 'REGISTRATION_ERROR',
            showAlert: true,
            message: ERROR_LOADING_PROGRAMS,
            programs: [],
            alertVariant: ALERT_ERROR,
        });
    }
};

const ProgramsProvider: React.FC<Props> = ({children}: Props) => {
    const user = React.useContext(jwtContext);
    const [programsState, setProgramsState] = React.useReducer(programsReducer, {
        loading: true,
        showAlert: false,
        programs: [],
        message: '',
        alertVariant: 'success'
    });

    const clearRegistrationError = useCallback(async () => {
        setProgramsState({type: 'CLEAR_REGISTRATION_ERROR'});
    }, [setProgramsState]);

    const resetRegistrationStatus = useCallback(async (course: ProgramCourse) => {
        setProgramsState({type: 'SWITCH_PAYMENT', course: course});
    }, [programsState, setProgramsState]);

    const answerSurvey = useCallback(async (surveyAnswers: SurveyAnswers, program: Program) => {
        if (!user) {
            return false;
        }

        const programIndex = programsState.programs.findIndex(
            (p: Program) => p.programUUID === program.programUUID
        );

        const url = new URL(`/v1/survey/${program.programUUID}`, apiEndpoint);
        const responseRegistrationAction = await user.apiFetch(url.toString(), {
            method: 'POST',
            body: JSON.stringify(surveyAnswers)
        });
        if (responseRegistrationAction.status !== 200) {
            setProgramsState({
                type: 'REGISTRATION_ERROR',
                showAlert: true,
                message: 'Unable to save survey',
                programs: programsState.programs,
                alertVariant: ALERT_ERROR
            });
            return false;
        }

        programsState.programs[programIndex].finishedSurvey = true;
        setProgramsState({
            type: 'SET_PROGRAMS',
            programs: programsState.programs,
            showAlert: true,
            message: 'Successfully submitted Survey',
            alertVariant: ALERT_SUCCESS
        });
        return true;
    }, [programsState.programs])

    const canPerformRegistrationAction = (
        activeCourse: ProgramCourse,
        activeProgram: string
    ): boolean => {
        if (!programsState.programs) {
            return false;
        }

        const overlap = hasOverlap(activeCourse, activeProgram);

        if (overlap) {
            setProgramsState({
                type: 'REGISTRATION_ERROR',
                showAlert: true,
                message: OVERLAP_MESSAGE,
                programs: programsState.programs,
                alertVariant: ALERT_ERROR,
            });
        }

        return !overlap;
    }

    const hasMultipleRegistrationViolation = useCallback((activeCourse: ProgramCourse, activeProgram: string): boolean => {
        const activeProgramIndex = programsState.programs.findIndex(
            (program: Program) => program.name === activeProgram
        );
        const activeCourseIndex = programsState.programs[activeProgramIndex].courses.findIndex(
            (course: ProgramCourse) => course.courseSelectionUUID === activeCourse.courseSelectionUUID
        );
        const courseToRegister = programsState.programs[activeProgramIndex].courses[activeCourseIndex];
        const program = programsState.programs[activeProgramIndex];
        let violation = false;
        const isWaitlist = activeCourse.status === STATUS_WAITLIST;


        if ([STATUS_OPEN, STATUS_WAITLIST].includes(activeCourse.status) && !courseToRegister.timeConflictOverride) {
            if (programsState.programs[activeProgramIndex].courses.some((r: ProgramCourse) => {
                if (isWaitlist || r.timeConflictOverride) {
                    return false;
                }
                if (program.oneSectionPerTerm && !courseToRegister.oneSectionPerTermOverride && r.courseUUID === courseToRegister.courseUUID && r.termID === courseToRegister.termID) {
                    return r.status === STATUS_REGISTERED
                }
            })) {
                violation = true;
            }
        }

        return violation;
    }, [programsState.programs])

    const hasOverlap = useCallback((activeCourse: ProgramCourse,
        activeProgram: string
    ): boolean => {
        const activeProgramIndex = programsState.programs.findIndex(
            (program: Program) => program.name === activeProgram
        );
        const activeCourseIndex = programsState.programs[activeProgramIndex].courses.findIndex(
            (course: ProgramCourse) => course.courseSelectionUUID === activeCourse.courseSelectionUUID
        );
        const courseToRegister = programsState.programs[activeProgramIndex].courses[activeCourseIndex];
        let overlap = false;

        if ([STATUS_OPEN, STATUS_WAITLIST].includes(activeCourse.status) && !courseToRegister.timeConflictOverride) {
            if (programsState.programs[activeProgramIndex].courses.some((r: ProgramCourse) =>
                    r.status === STATUS_REGISTERED && csOverlap(r, courseToRegister)
            )) {
                overlap = true;
            }
        }

        return overlap;
    }, [programsState.programs]);

    const performRegistrationAction = useCallback(async (
        activeCourse : ProgramCourse,
        activeProgram : string,
        reason ?: string,
    ): Promise<boolean> => {
        if (!programsState.programs || !user) {
            return false;
        }

        let actionUrl = '';
        let method = '';
        const overlap = activeCourse.status !== STATUS_WAITLIST && hasOverlap(activeCourse, activeProgram);
        const multipleRegistrationError = hasMultipleRegistrationViolation(activeCourse, activeProgram)
        const activeProgramIndex = programsState.programs.findIndex(
            (program: Program) => program.name === activeProgram
        );
        const activeCourseIndex = programsState.programs[activeProgramIndex].courses.findIndex(
            (course: ProgramCourse) => course.courseSelectionUUID === activeCourse.courseSelectionUUID
        );

        if (multipleRegistrationError) {
            setProgramsState({
                type: 'REGISTRATION_ERROR',
                showAlert: true,
                message: MULTIPLE_REGISTRATION_MESSAGE,
                programs: programsState.programs,
                alertVariant: ALERT_ERROR,
            });
            return false;
        }
        if (overlap) {
            setProgramsState({
                type: 'REGISTRATION_ERROR',
                showAlert: true,
                message: OVERLAP_MESSAGE,
                programs: programsState.programs,
                alertVariant: ALERT_ERROR,
            });
            return false;
        }

        if (activeCourse.status === STATUS_REGISTERED_WITH_CREDITS_PENDING_PAYMENT){
            programsState.programs[activeProgramIndex].courses[activeCourseIndex].status = STATUS_REGISTERED_WITH_CREDITS;
            setProgramsState({
                type: 'SET_PROGRAMS',
                showAlert: true,
                message: 'Your Payment has been received and your course credits have been applied.',
                programs: programsState.programs,
                alertVariant: ALERT_SUCCESS,
            });
            return true
        }

        switch (activeCourse.status) {
            case STATUS_REGISTERED:
                actionUrl = `/${activeCourse.courseSelectionUUID}`;
                method = 'DELETE';
                break;
            case STATUS_REGISTERED_WITH_CREDITS:
                actionUrl = `/${activeCourse.courseSelectionUUID}`;
                method = 'DELETE';
                break;
            case STATUS_WAITLISTED:
                actionUrl = `/waitlist/${activeCourse.courseSelectionUUID}`;
                method = 'DELETE';
                break;
            case STATUS_WAITLIST:
                actionUrl = `/waitlist/${activeCourse.courseSelectionUUID}`;
                method = 'POST';
                break;
            case STATUS_OPEN:
            default:
                actionUrl = `/${activeCourse.courseSelectionUUID}`;
                method = 'POST';
                break;
        }

        const url = new URL(`/v1/register${actionUrl}`, apiEndpoint);

        let init : RequestInit = {
            method: method,
            // @ts-ignore supported by modern browsers
            signal: AbortSignal.timeout(10000)//10 second timeout
        };

        if (activeCourse.courseQuestions) {
            init.body = JSON.stringify({
                activeCourse: activeCourse,
                reason,
            });
        } else {
            init.body = JSON.stringify({
                reason,
            });
        }

        let responseJSON = null;
        let responseStatus = 600;
        let fetchError: boolean|string = false;

        try {
            const responseRegistrationAction = await user.apiFetch(url.toString(), init);
            responseStatus = responseRegistrationAction.status;
            responseJSON = await responseRegistrationAction.json();
        } catch (e) {
            if (e instanceof SyntaxError) {
                fetchError = 'Invalid response from server. Please try again.';
                console.log('syntax error');
            } else if (e instanceof Error) {
                fetchError = e.message;
            } else {
                fetchError = `Unknown Error from server. ${e}`
                console.log('error', e);
            }
            console.log('error registering', e);
        }

        if (!responseJSON) {
            setProgramsState({
                type: 'REGISTRATION_ERROR',
                showAlert: true,
                message: `${ERROR_REGISTERING} ${fetchError}`,
                programs: programsState.programs,
                alertVariant: ALERT_ERROR,
            });
            return false;
        }

        if (responseStatus !== 200) {
            let newPrograms = programsState.programs;

            if (responseStatus === 406) {//api says pull down the programs again
                try {
                    newPrograms = await fetchPrograms(user.apiFetch);
                } catch (e) {
                    if (e instanceof SyntaxError) {
                        fetchError = 'Invalid response from server. Refreshing programs please try again.';
                        console.log('syntax error');
                    } else if (e instanceof Error) {
                        fetchError = e.message;
                    } else {
                        fetchError = `Unknown Error from server. ${e}`
                        console.log('error', e);
                    }
                    console.error('error forced refresh', e);
                    setProgramsState({
                        type: 'REGISTRATION_ERROR',
                        showAlert: true,
                        message: `ERROR_REGISTERING ${fetchError}`,
                        programs: programsState.programs,
                        alertVariant: ALERT_ERROR,
                    });
                    return false;
                }
            }

            programsState.programs[activeProgramIndex].courses[activeCourseIndex].registrationError = true;
            let errorMessage = '';

            switch (activeCourse.status) {
                case STATUS_OPEN:
                    errorMessage = 'Unable to register for event.';
                    break;
                case STATUS_REGISTERED:
                    errorMessage = 'Unable to cancel registration.';
                    break;
                case STATUS_REGISTERED_WITH_CREDITS:
                    errorMessage = 'Unable to cancel registration.';
                    break;
                case STATUS_WAITLISTED:
                    errorMessage = 'Unable to cancel waitlist.';
                    break;
                case STATUS_WAITLIST:
                    errorMessage = 'Unable to process waitlist request.';
                    break;
                case STATUS_CLOSED_NO_WAITLIST:
                    errorMessage = 'This course has filled up.';
                    break;
            }

            if (responseJSON.hint) {
                errorMessage += ' ' + responseJSON.hint;
            }

            setProgramsState({
                type: 'REGISTRATION_ERROR',
                showAlert: true,
                message: errorMessage,
                programs: newPrograms,
                alertVariant: ALERT_ERROR
            });
            return false;
        }

        let message : string;

        switch (activeCourse.status) {

            case STATUS_REGISTERED:
                message = 'Registration removed';
                programsState.programs[activeProgramIndex].courses[activeCourseIndex].status = STATUS_OPEN;
                break;
            case STATUS_WAITLISTED:
                message = 'Waitlist removed';
                programsState.programs[activeProgramIndex].courses[activeCourseIndex].status = STATUS_WAITLIST;
                break;
            case STATUS_WAITLIST:
                message = 'You have been added to the waitlist';
                programsState.programs[activeProgramIndex].courses[activeCourseIndex].status = STATUS_WAITLISTED;
                break;
            case STATUS_OPEN:
            default:
                message = 'Registration successful';
                programsState.programs[activeProgramIndex].courses[activeCourseIndex].status = STATUS_REGISTERED;

                // this conditional is necessary because when the active course has course questions,
                // the status doesn't get changed from 'open' to 'registered' causing the timer to break
                if (activeCourse.courseQuestions) {
                    activeCourse = {...activeCourse, status: STATUS_REGISTERED}
                }

                break;
        }

        setProgramsState({
            type: 'SET_PROGRAMS',
            programs: programsState.programs,
            showAlert: true,
            message: message,
            alertVariant: ALERT_SUCCESS
        });

        // store the time that the registration will expire
        if (activeCourse.status === STATUS_REGISTERED && activeCourse.requiresPayment) {
            localStorage.setItem('registration-course-' + responseJSON.courseSectionID, JSON.stringify({
                registrationId: responseJSON.registrationId,
                courseSectionID: responseJSON.courseSectionID,
                timeout: new Date(Date.now() + (responseJSON.timeout * 60 * 1000)).toJSON(),
            }));
        }

        return true;
    }, [setProgramsState, programsState.programs, hasOverlap]);

    const showErrorMessage = (message : string) => {
        setProgramsState({
            type: 'REGISTRATION_ERROR',
            showAlert: true,
            message: message,
            programs: programsState.programs,
            alertVariant: ALERT_ERROR
        });
    }

    useEffect(() => {
        if (!user) {
            return;
        }

        loadPrograms(user.apiFetch, setProgramsState, false).catch(console.error);

        const interval = setInterval(async () => {
            if (document.hidden) {//background tab just bail
                return;
            }
            await loadPrograms(user.apiFetch, setProgramsState, true);
        }, 5 * 60 * 1000);//update every 5 minutes
        return () => clearInterval(interval);
    }, [user]);

    const refreshPrograms = useCallback(async () => {
        if (user) {
            await loadPrograms(user.apiFetch, setProgramsState, false);
        }
    }, [user, setProgramsState]);

    return (
        <ProgramsProviderContext.Provider
            value={[// see ProgramsProviderContext
                programsState,
                performRegistrationAction,
                clearRegistrationError,
                answerSurvey,
                canPerformRegistrationAction,
                resetRegistrationStatus,
                showErrorMessage,
                refreshPrograms
            ]}>
            {children}
        </ProgramsProviderContext.Provider>
    );
};

export default ProgramsProvider;

