import NavHeader from "../components/Navigation/NavHeader";
import {type ReactElement, useEffect, useState} from "react";
import {Box, Divider, Link, Stack, Typography} from "@mui/material";
import moment from "moment";
import DeleteIcon from '@mui/icons-material/Delete';
import CartPaymentForm, {stripePromise} from "@/components/Forms/CartPaymentForm.tsx";
import {formatter} from "@/Helpers/formatter.ts";
import {useCartContext} from "@/components/Providers/CartProvider.tsx";
import SystemAlert from "@/components/SystemAlert.tsx";
import {CreateSetupIntentResponse} from "@/types/CreateSetupIntentResponse.ts";
import {SetupIntent} from "@/types/SetupIntent.tsx";
import useStripeCreateSetupIntent from "@/hooks/useStripeCreateSetupIntent.tsx";
import {Elements} from "@stripe/react-stripe-js";
import TotalRow from "@/components/Cart/TotalRow.tsx";
import MainDisplayContainer from "@/components/Layout/MainDisplayContainer.tsx";

const CartPage = () : ReactElement => {
    const {cart, deleteCartItem, getCartAlertStatus, resetCartAlertStatus, getCartTotal, cartIsEmpty} = useCartContext();
    const totalCost = getCartTotal();

    const cartStatus = getCartAlertStatus();

    const [isSetup, setIsSetup] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [setupIntent, setSetupIntent] = useState<SetupIntent | null>(null);
    const createSetupIntent = useStripeCreateSetupIntent();

    useEffect(() => {
        void getSetupIntent();
        setIsSetup(true);
    }, [isSetup]);

    const getSetupIntent = async () => {
        setErrorMessage('');

        const response = await createSetupIntent();

        if (response && response.ok) {
            const json = await response.json() as CreateSetupIntentResponse;

            if (json.errorCode !== 0) {
                setErrorMessage(json.message);
                return;
            }

            setSetupIntent(json.data as SetupIntent);

            return;
        } else {
            setErrorMessage('An unknown error has occurred.');
        }
    };

    return <Stack sx={{backgroundColor: '#631d79', flexDirection: 'column', height: '100vh'}}>
        <NavHeader showNav={true}/>
        <MainDisplayContainer
            sx={{
                pt: '30px',
                pb: '30px'
            }}
        >
            {cartIsEmpty() && <Box
                sx={{
                    flexGrow: 1,
                    display: 'flex',
                    px: '49.5px',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        backgroundColor: '#fff',
                        px: 2,
                        pt: 2,
                    }}
                >
                    <Box>
                        Your cart is currently empty. You can add registrations by visiting the
                        <Link
                            href="/programs"
                            sx={{
                                display: 'inline',
                                ml: 1,
                            }}
                        >
                            Register section.
                        </Link>
                    </Box>
                </Box>
            </Box>}

            {!cartIsEmpty() && <Box
                sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: {xs: 'column-reverse', md: 'row'},
                    px: {xs: '15px', md: '49.5px'},
                    maxHeight: {md: '80vh'}
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                        width: {xs: '100%', md: '60%'},
                        backgroundColor: '#fff',
                        px: 2,
                        pt: 2,
                        overflowY: 'auto',
                    }}
                >
                    <Typography
                        variant={'h5'}
                        sx={{
                            pt: 1,
                            pb: 1,
                            color: '#000',
                        }}
                    >
                        Payment Method
                    </Typography>
                    <Divider/>

                    {setupIntent && <Elements stripe={stripePromise} options={{
                        clientSecret: setupIntent.clientSecret,
                        appearance: {
                            theme: 'stripe',
                            variables: {
                                fontWeightNormal: '500',
                                borderRadius: '8px',
                                colorPrimary: '#000',
                                // @ts-ignore
                                tabIconSelectedColor: '#fff',
                                gridRowSpacing: '16px'
                            },
                            rules: {
                                '.Tab, .Input, .Block, .CheckboxInput, .CodeInput': {
                                    boxShadow: '0px 3px 10px rgba(18, 42, 66, 0.08)',
                                },
                                '.BlockDivider': {
                                    backgroundColor: '#ebebeb'
                                },
                                '.Tab--selected, .Tab--selected:hover': {
                                    backgroundColor: '#631d79',
                                    color: '#fff'
                                }
                            }
                        }
                    }}>
                        <CartPaymentForm cost={totalCost}/>
                    </Elements>}
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                        width: {xs: '100%', md: '40%'},
                        backgroundColor: '#eee',
                        px: 0,
                        pt: 2,
                    }}
                >
                    <Typography
                        variant={'h5'}
                        sx={{
                            mx: 1,
                            py: 1,
                            color: '#000',
                        }}
                    >
                        Summary
                    </Typography>
                    <Divider
                        sx={{
                            mx: 1,
                        }}
                    />

                    <Box
                        sx={{
                            flexGrow: 1,
                            overflowY: {xs: 'visible', md: 'auto'},
                            mx: 1,
                        }}
                    >
                        {cart?.cartItems.map(cartItem => <Box
                            key={`payment-due-${cartItem.id}`}
                            sx={{
                                pl: 1,
                            }}
                        >
                            <Stack
                                sx={{
                                    flexDirection: 'row',
                                    py: 1,
                                }}
                            >
                                <Box
                                    sx={{
                                        flexGrow: 1,
                                    }}
                                >
                                    <Box sx={{
                                        display: 'inline',
                                        pr: 1,
                                        color: '#000',
                                        fontWeight: 800
                                    }}>{cartItem.name}</Box>
                                    {cartItem.deliveryMethod &&
                                        <Box sx={{
                                            display: 'inline',
                                            pr: 1,
                                            color: '#000'
                                        }}>{cartItem.deliveryMethod && ','}</Box>}
                                    <Box sx={{
                                        display: 'inline',
                                        pr: 1,
                                        fontWeight: 800,
                                        color: '#000'
                                    }}>Section {cartItem.sectionNumber}</Box>

                                    <Box
                                        sx={{px: 1}}>{moment(cartItem.dateStartFull).format('MM/DD')} - {moment(cartItem.dateEndFull).format('MM/DD')},</Box>
                                    <Box sx={{px: 1, display: 'inline'}}>{cartItem.meetingTime}</Box>

                                    <Box sx={{
                                        px: 1,
                                        color: '#000',
                                        fontWeight: 800
                                    }}>{formatter.format(cartItem.cost)}</Box>
                                </Box>
                                <DeleteIcon
                                    onClick={() => {
                                        deleteCartItem(cartItem.id);
                                    }}
                                    sx={{
                                        cursor: 'pointer',
                                        mr: 1,
                                    }}
                                />
                            </Stack>
                            <Divider/>
                        </Box>)}
                    </Box>

                    {!cartIsEmpty() && <TotalRow
                        totalCost={totalCost}
                        cartIsEmpty={cartIsEmpty()}
                        errorMessage={errorMessage}
                        sx={{
                            display: {xs: 'none', md: 'flex'},
                            backgroundColor: '#eee',
                            borderTop: '1px solid #222'
                        }}
                    />}
                </Box>
            </Box>}
            <SystemAlert
                show={cartStatus.showAlert}
                alertText={cartStatus.message}
                clearSystemAlert={resetCartAlertStatus}
                variant={cartStatus.alertVariant}
                isFixed={true}
            />
        </MainDisplayContainer>
    </Stack>
};

export default CartPage;
