import React, {ChangeEvent} from 'react';
import Form from 'react-bootstrap/Form';
import {FormikValues, useFormikContext} from 'formik';
import {Col} from 'react-bootstrap';
import {FormikContextType} from "formik/dist/types";

export type StandardFieldOption = {
    label : string;
    value : string;
    checked : boolean;
};

type Props = {
    name : string;
    type : string;
    label : string;
    options? : Array<StandardFieldOption>;
    disabled? : boolean;
    handleSetFormIsModified : (formIsModified: boolean) => Promise<void>;
    arrayName? : string;
    iteration? : number;
    className? : string;
    onChange? : (e: ChangeEvent<any>) => void;
    fieldLabelClass? : string;
    hideCheckboxDescription? : boolean;
    errorNoticeOverride? : string;
    placeHolder? : string;
};

const StandardField = ({
    name,
    type,
    label,
    options,
    disabled,
    handleSetFormIsModified,
    arrayName,
    iteration,
    className,
    onChange,
    fieldLabelClass,
    hideCheckboxDescription,
    errorNoticeOverride,
    placeHolder
} : Props) => {
    const { values, handleChange, errors, setFieldValue } : FormikContextType<FormikValues> = useFormikContext();
    const fieldName = arrayName && iteration !== undefined ? `${arrayName}[${iteration}].${name}` : name;
    const fieldValue = arrayName && iteration !== undefined ? values[arrayName][iteration][name] : values[name];
    // @ts-ignore
    const errorsName = arrayName && iteration !== undefined && errors[arrayName] && errors[arrayName][iteration] ? errors[arrayName][iteration][name] : errors[fieldName];

    return (
        <Form.Group>
            <Form.Row>
                {(type === 'text' || type === 'date' || type === 'time' || type === 'password') && (
                    <React.Fragment>
                        {label && (
                            <Form.Label column={false} className={fieldLabelClass}>{label}&nbsp;</Form.Label>
                        )}
                        <Form.Control
                            type={type}
                            name={fieldName}
                            value={fieldValue}
                            onChange={async (e) => {
                                handleChange(e);
                                onChange && onChange(e);
                                await handleSetFormIsModified(true);
                            }}
                            isInvalid={!!errorsName || !!errorNoticeOverride}
                            disabled={disabled}
                            className={className ? className : ''}
                            placeholder={placeHolder ? placeHolder : ''}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errorsName ? errorsName : (errorNoticeOverride ? errorNoticeOverride : '')}
                        </Form.Control.Feedback>
                    </React.Fragment>
                )}
                {(type === 'textarea') && (
                    <React.Fragment>
                        {label && (
                            <Form.Label column={false} className={fieldLabelClass}>{label}</Form.Label>
                        )}
                        <Form.Control
                            as={type}
                            name={fieldName}
                            value={fieldValue}
                            onChange={async (e) => {
                                handleChange(e);
                                onChange && onChange(e);
                                await handleSetFormIsModified(true);
                            }}
                            isInvalid={!!errorsName || !!errorNoticeOverride}
                            disabled={disabled}
                            className={className ? className : ''}
                        />
                    </React.Fragment>
                )}
                {(type === 'checkbox' || type === 'radio') && (
                    <React.Fragment>
                        {label && (
                            <Col xs={12} className={fieldLabelClass}>
                                <Form.Label column={false}>{label}</Form.Label>
                                {(!!errorsName || !!errorNoticeOverride) && (
                                <Form.Control.Feedback type="invalid" className="d-block">
                                    {errorsName ? errorsName : (errorNoticeOverride ? errorNoticeOverride : '')}
                                </Form.Control.Feedback>
                                )}
                            </Col>
                        )}
                        {options && options.map((option : StandardFieldOption, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <Col xs={12} lg={options.length <= 3 ? 12 : 4}>
                                        {type === 'checkbox' && (
                                            <Form.Check
                                                className="mb-2"
                                                type={type}
                                                name={fieldName}
                                                label={!hideCheckboxDescription ? option.label : false}
                                                value={option.value}
                                                onChange={async (e : any) => {
                                                    const value = e.target.checked ? option.value : null;
                                                    await setFieldValue(`${fieldName}.${index}`, value);
                                                    onChange && onChange(e);
                                                    await handleSetFormIsModified(true);
                                                }}
                                                checked={fieldValue && fieldValue.includes(option.value)}
                                                isInvalid={!!errorsName || !!errorNoticeOverride}
                                                id={`${fieldName}-${index}`}
                                                disabled={disabled}
                                            />
                                        )}
                                        {type === 'radio' && (
                                            <React.Fragment>
                                            <input
                                                className="mb-2"
                                                type={type}
                                                name={fieldName}
                                                value={option.value}
                                                onClick={async (e : any) => {
                                                    const value = e.target.checked ? option.value : null;
                                                    setFieldValue(`${fieldName}`, value);
                                                    await handleSetFormIsModified(true);
                                                }}
                                                defaultChecked={fieldValue && fieldValue.includes(option.value)}
                                                id={`${fieldName}-${index}`}
                                                disabled={disabled}
                                            /> &nbsp; {option.label}
                                            </React.Fragment>
                                        )}
                                    </Col>
                                </React.Fragment>
                            );
                        })}
                    </React.Fragment>
                )}
                {(type === 'select') && (
                    <React.Fragment>
                        {label && (
                            <Form.Label column={false} className={fieldLabelClass}>{label}</Form.Label>
                        )}
                        <Form.Control
                            type={type}
                            as="select"
                            name={fieldName}
                            value={fieldValue}
                            onChange={async (e) => {
                                handleChange(e);
                                onChange && onChange(e);
                                await handleSetFormIsModified(true);
                            }}
                            isInvalid={!!errorsName || !!errorNoticeOverride}
                            disabled={disabled}
                            className={className ? className : ''}
                        >
                            <option value=""> -- Select --</option>
                            {options && options.map((option : StandardFieldOption, index) => {
                                return <option
                                    value={option.value}
                                    key={index}
                                >{option.label}</option>;
                            })}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            {errorsName ? errorsName : (errorNoticeOverride ? errorNoticeOverride : '')}
                        </Form.Control.Feedback>
                    </React.Fragment>
                )}
            </Form.Row>
        </Form.Group>
    );
};

export default StandardField;
