import React from 'react';
import GeneralRegisterButton, {
    GeneralRegisterButtonProps
} from "@/components/Program/RegisterButtons/GeneralRegisterButton.tsx";

const SubmitPaymentButton = (props : GeneralRegisterButtonProps): React.ReactElement => (
    <GeneralRegisterButton
        buttonText="Submit Payment"
        buttonTextAction="Switching"
        {...props}
    />
);

export default SubmitPaymentButton;
