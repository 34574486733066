import React from 'react';
import NavHeader from "../components/Navigation/NavHeader";
import {Container, Row} from "react-bootstrap";
import {
    Program,
    ProgramsProviderContext,
    STATUS_PARTIAL,
    STATUS_REGISTERED,
    STATUS_REGISTERED_WITH_CREDITS,
    STATUS_REGISTERED_WITH_CREDITS_PENDING_PAYMENT,
    STATUS_WAITLISTED
} from "../components/Providers/ProgramsProvider";
import ProgramCourses from "../components/Program/ProgramCourses";
import SystemAlert from "../components/SystemAlert";
import NoRegisteredCourses from "../components/Program/NoRegisteredCourses";
import CircularLoadingIndicator from "../components/CircularLoadingIndicator";
import {useCartContext} from "@/components/Providers/CartProvider.tsx";

const MyRegistrations = () => {
    const {getCartAlertStatus, resetCartAlertStatus} = useCartContext();
    const [programsState,,clearRegistrationError] = React.useContext(ProgramsProviderContext);

    const cartStatus = getCartAlertStatus();
    let noRegisteredCourses = true;

    return (
        <>
            <NavHeader showNav={true}/>
            <Container>
                <Row className="program-selected">
                </Row>
            </Container>
            <Container>
                {programsState.loading ? (
                    <CircularLoadingIndicator/>
                ) : (
                    <>
                        {programsState.programs.map((program: Program, index) => {
                            const courses = program.courses.filter(
                                (course ) =>
                                    course.status === STATUS_WAITLISTED ||
                                    course.status === STATUS_REGISTERED ||
                                    course.status === STATUS_REGISTERED_WITH_CREDITS_PENDING_PAYMENT ||
                                    course.status === STATUS_REGISTERED_WITH_CREDITS ||
                                    course.status === STATUS_PARTIAL
                            );

                            if (courses.length > 0) {
                                noRegisteredCourses = false;
                            }

                            return <ProgramCourses
                                key={`${index}-${program.name}`}
                                programName={program.name}
                                myRegistrations={true}
                                courses={courses}
                            />
                        })}
                        {noRegisteredCourses && (
                            <NoRegisteredCourses/>
                        )}
                    </>
                )}
            </Container>
            <SystemAlert
                show={programsState.showAlert}
                alertText={programsState.message}
                clearSystemAlert={clearRegistrationError}
                variant={programsState.alertVariant}
                isFixed={true}
            />
            <SystemAlert
                show={cartStatus.showAlert}
                alertText={cartStatus.message}
                clearSystemAlert={resetCartAlertStatus}
                variant={cartStatus.alertVariant}
                isFixed={true}
            />
        </>
    );
};

export default MyRegistrations;
