import ModalBase from './ModalBase';
import {Jumbotron} from "react-bootstrap";

type Props = {
    showPartialPaymentsModal : boolean;
    setShowPartialPaymentsModal : (showPartialPaymentsModal : boolean) => void;
    setShowPartialPayments : (showPartialPayments : boolean) => void;
}

const PartialPaymentsModal = ({showPartialPaymentsModal, setShowPartialPaymentsModal, setShowPartialPayments} : Props) => {
    const handleCloseModal = () => {
        setShowPartialPaymentsModal(false);
    };

    const handleShowPartials = () => {
        setShowPartialPaymentsModal(false);
        setShowPartialPayments(true);
    }

    return (
        <ModalBase
            show={showPartialPaymentsModal}
            title="Partial Payments"
            size="lg"
            hideConfirmButton={true}
            hideCloseButton={true}
            handleClose={handleCloseModal}
        >
            <Jumbotron className="mt-0 px-0">
                <div className="markdown">
                    <p>You have partially submitted payments. All fees must be paid in full by the due date for the
                        activity. Any registrations with outstanding fees after the due date will be cancelled.</p>

                    <a href={'#'} onClick={handleShowPartials}>Click here to view your partially completed payments</a>
                </div>
            </Jumbotron>
        </ModalBase>
    );
};

export default PartialPaymentsModal;
